import React, { useEffect } from 'react'
import styled from "styled-components"
import SecondaryButton from "../Button/SecondaryButton"

const ReviewGrid = styled.div`
  background: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 60px;
  padding: 30px 20px;
  @media(min-width:768px){
    padding: 40px 30px;
  }
  @media(min-width:992px){
    padding: 60px 30px;
  }

`
const ExploreMore = styled.div`
	display:block;
    width: fit-content;
    margin: auto;
`

const ReviewWdiget = () =>{
     useEffect(()=>{
        const script = document.createElement('script');
        script.src = "https://cdn.trustindex.io/loader.js?a1ac1c7158b5800df8467a5628d";
        script.async = true;
        script.defer = true;
        document.getElementById("widget-script").appendChild(script)
     },[])
	return(
        <ReviewGrid>
            <div id="widget-script"></div>
            <ExploreMore>
            <a href="https://www.google.com/search?q=probuiltsteel&oq=probuiltsteel&aqs=chrome..69i57j46i10i175i199i512j0i10i512j0i10i15i30i625j69i60l4.6447j0j4&sourceid=chrome&ie=UTF-8#lrd=0x88ef3a49e5fffba1:0xff8159e1ba632d80,3,,,," target="_blank"><SecondaryButton text="LEAVE US A REVIEW" /></a>
            </ExploreMore>
        </ReviewGrid>
	)
}

export default ReviewWdiget