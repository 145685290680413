import React from 'react'
import { Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/CallToAction"

import { AboutWrapper, HeroTitle, BreadCrumb } from "../components/Section"
import ReviewWdiget from "../components/ReviewWdiget"


const ReviewsPage = ({ location }) =>{
	return(
		<Layout location={location}>
      <SEO title="Reviews" description="Customers' reviews for probuilt steel" />
      <AboutWrapper>
      	<div className="container">
	      	<BreadCrumb top="-40px" mTop="-40px"><Link to="/">Home</Link> / <span>Reviews </span></BreadCrumb>
	      	<HeroTitle>What Our Customers Says</HeroTitle>
				<ReviewWdiget />
	      </div>
      </AboutWrapper>
      <CallToAction />
    </Layout>
	)
}

export default ReviewsPage